import React from "react"

import PageWrapper from "../styles/PageWrapper"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <PageWrapper>
    <Seo title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a page that doesn&#39;t exist...</p>
  </PageWrapper>
)

export default NotFoundPage
